import React from "react";
import ExteriorDetailing from "../../assets/images/EssentialRepairImage.png"
import ServicesSlider from "./ServicesSlider";
function ServicesProvider() {
  return (
    <section id="services-provider" className="services-provider section">
      <div className="container">
        <div className="container four-secton-box">
          <div className="row">
            <div className="col-md-3">
              <div className="info-item our-services-card" data-aos="fade" data-aos-delay="200">
                <h3>20 +</h3>
                <p>Years Experience</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info-item our-services-card" data-aos="fade" data-aos-delay="400">
                <h3>50000 +</h3>
                <p>Projects Done</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info-item our-services-card" data-aos="fade" data-aos-delay="300">
                <h3>100 %</h3>
                <p>Customer Satisfaction</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info-item our-services-card" data-aos="fade" data-aos-delay="300">
                <h3>5</h3>
                <p>Customer Rating</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container sec-servic-three">
        <div className="row">
          <div className="col-lg-10">
            <p className="sub-heading-highlighted primary-gradient-text-small">Our Services</p>
            <h2 className="primary-gradient-text">Ensuring Best Performance and Looks For Your Car, Always!</h2>
            <p className="text-bottom pt-0 pb-4">
              At Wrenchit, we provide a full suite of luxury services tailored to optimize & pamper your car. From routine maintenance to comprehensive repairs to luxurious detailing, you can trust us for excellence in every service you need.
            </p>
          </div>
         
          <div className="col-lg-2" >
            <div className="button-home">
                All Services
              
            </div>
          </div>
        </div>
        <ServicesSlider/>
      </div>
    </section>
  );
}

export default ServicesProvider;
