import React from 'react'
import { Helmet } from 'react-helmet-async'
const InsuranceClaim = () => {
  return (
    <div>
       <Helmet>
        <title>Easy Insurance Claims | Wrenchit’s Luxury Car Service in Pune</title>
        <meta
          name="description"
          content="Streamline your insurance claim with Wrenchit. Enjoy cashless claims and top-quality repairs and paint jobs for your luxury car service in Pune."
        />
        <link rel="canonical" href="https://wrenchit.co.in/insurance-claim" />

        "<meta property="og:title" content="Easy Insurance Claims | Wrenchit’s Luxury Car Service in Pune" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/insurance-claim" />
        <meta property="og:description" content="Streamline your insurance claim with Wrenchit. Enjoy cashless claims and top-quality repairs and paint jobs for your luxury car service in Pune." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/logo-wrenchit.png" />
      </Helmet>
        <main className="main">
        {/* Page Title */}
        <div className="page-title services-banner" data-aos="fade">
          <div className="heading">
            <div className="container">
              <div className="row d-flex justify-content-center text-center">
                <div className="col-lg-8 main-banner">
                  <h1 className="">Insurance Claims</h1>
                  <p className="mb-0">Services &gt; Insurance Claims</p>
                </div>
              </div>
            </div>
          </div>
          <div>
           
          </div>
          </div>
          <div>
          <div className='container my-lg-5 py-60'>
          <h2 className="service_h2">
            Insurance Claims
            </h2>
          <p>
            Getting your brand new car painted just because of a small accident could be a hassle. Claiming insurance and getting the right quality paint job is what worries most of the people. We offer cashless insurance claims with almost all available companies in the market, which means, you can easily drop of your car at our body-shop and let us take care of the rest. We handle insurance claims with just as much attention and honesty as we handle our custom paint jobs. We promise Grade-A material application and the best possible finish our your car.


            </p>

          </div>
          </div>
          </main>
    </div>
  )
}

export default InsuranceClaim