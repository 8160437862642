import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
const RecentBlogs = ({ currentBlogId }) => {
    const [recentBlogs, setRecentBlogs] = useState([]); // State to hold recent blogs
    const [error, setError] = useState(null); // State to handle errors
    const [loading, setLoading] = useState(true); // State to manage loading

    useEffect(() => {
        const fetchRecentBlogs = async () => {
            try {
                const response = await axios.get("https://wrenchit.co.in/admin/public/api/all-posts"); // Replace with your API endpoint for recent blogs
                // Filter out the current blog
                const filteredBlogs = response.data.posts.filter(blog => blog.id !== currentBlogId);
                setRecentBlogs(filteredBlogs); // Set the fetched data
            } catch (error) {
                setError("Failed to fetch recent blogs.");
                console.error("Error fetching recent blogs:", error);
            } finally {
                setLoading(false); // Stop loading regardless of success or failure
            }
        };

        fetchRecentBlogs();
    }, [currentBlogId]);

    if (loading) {
        return <div>Loading...</div>; // Show loading state while fetching data
    }

    if (error) {
        return <div>{error}</div>; // Show error message if fetching fails
    }

    return (
        <div>
            <div className='recent_post'>
                <h3 className='mb-4'>
                    Recent Posts
                </h3>
                {recentBlogs.map((blog) => (
                    <div className='' key={blog.id}>
                <div className='row'>
                    <div className='col-md-4'>
                        <img src={blog.image} alt={blog.title} className='img img-fluid' />
                    </div>
                    <div className='col-md-8'>
                        <div className='latest_blog'>
                            <h3 className=''>
                            {blog.title}
                            </h3>
                            <p>
                            Date: {new Date(blog.created_at).toLocaleDateString()}
                            </p>
                            <Link className='readmore_btn' to= {`/blogs/${blog.slug}`}>
                                Read More
                            </Link>
                        </div>


                    </div>
                </div>
                </div>
))}
            </div>
        </div>
    )
}

export default RecentBlogs