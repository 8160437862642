import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import blog from '../assets/images/blog/blog-1.jpg'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import DOMPurify from 'dompurify';
import RecentBlogs from '../Components/RecentBlogs'
const Blog = () => {
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]); // State to hold the list of blogs
    const [loading, setLoading] = useState(true); // State to manage loading
    const [error, setError] = useState(null); // State to handle errors

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get("https://wrenchit.co.in/admin/public/api/all-posts");
                setBlogs(response.data.posts); // Assuming the API returns an object with a posts array
            } catch (error) {
                setError("Failed to fetch blogs.");
                console.error("Error fetching blogs:", error);
            } finally {
                setLoading(false); // Stop loading regardless of success or failure
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // You could replace this with a spinner or more elegant loading state
    }

    if (error) {
        return <div>{error}</div>; // Show error message if fetching fails
    }

    // Function to truncate description to the first 80 words
    const truncateDescription = (description) => {
        // Remove HTML tags
        const plainText = DOMPurify.sanitize(description.replace(/<[^>]*>/g, ''));
        const words = plainText.split(/\s+/); // Split by whitespace

        if (words.length > 10) {
            return words.slice(0, 15).join(' ') + '...'; // Add ellipsis if truncated
        }
        return plainText; // Return full description if it's less than or equal to 80 words
    };


    return (
        <div>
            <Helmet>
                <title>Blog Wrenchit  </title>
                <meta
                    name="description"
                    content="" />
                <link rel="canonical" href="https://wrenchit.co.in/about" />

                "<meta property="og:title" content="" />
                <meta property="og:site_name" content="Wrenchit" />
                <meta property="og:url" content="" />
            </Helmet>
            <div className='blog_page'>
                <h1><b>
                    Our blogs
                </b>
                </h1>
            </div>
            <div className='blog_grid'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='row'>
                                {blogs.map(blog => (
                                    <div className='col-md-6 mb-5'>
                                        <img
                                            src={blog.image || "fallback_image_url.jpg"} // Provide a fallback image URL here
                                            alt={blog.title}
                                            className='img img-fluid'></img>
                                        <div className='row align-items-center'>
                                            <div className='col-md-8'>
                                                <h2>
                                                    {blog.title}
                                                </h2>
                                            </div>
                                            <div className="col-md-4">
                                                <p>Date: {new Date(blog?.created_at).toLocaleDateString()}
                                                </p>
                                            </div>


                                        </div>

                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(truncateDescription(blog.description)), // Truncate description
                                            }}
                                        />
                                        <button className='btn ' onClick={() => navigate(`/blogs/${blog.slug}`)}>Read Article</button>


                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-md-4'>
                           <RecentBlogs/>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Blog