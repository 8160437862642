import React from 'react'
import ExteriorDetailing from "../../assets/images/EssentialRepairImage.png"
import TruncatedText from '../TruncatedText'; // Ensure this path is correct

const ServicesCard = ({ delay, title, image , description, link ,alt }) => {
  return (
    <div className='three-blog-sect'>
         <div className=" member truncated-text" data-aos="fade-up" data-aos-delay={delay}>
    <div className="member-img">
      <img src={image} className="img-fluid" alt={alt} />
    </div>
    <div className="member-info text-center">
     
      <h3 style={{ paddingBottom: "12px" }} className="services-card-title mt-0 pt-4">{title}</h3>
      <TruncatedText text={description} />

      <div className="button-home">
        <a className="btn-getstarted" href={link}>
          LEARN MORE
        </a>
      </div>
    </div>
  </div>
    </div>
  )
}

export default ServicesCard