import React from 'react'
import essential from "../../assets/images/new-images/essential-services.webp"
import master from "../../assets/images/new-images/master-daint-paint.webp"
import glamour from "../../assets/images/new-images/glamourous-details.webp"
import creaming from "../../assets/images/new-images/ceramic-coat.webp"
import PPF from "../../assets/images/new-images/ppf-services.webp"
import periodic from "../../assets/images/new-images/periodic-maintnce.webp"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServicesCard from './ServicesCard';
const ServicesSlider = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3, // Adjust this based on how many logos you want to show at once
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024, // For screens less than 1024px wide
          settings: {
            slidesToShow: 2, // Show 2 slides at a time
            slidesToScroll: 1,
            arrows: false,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600, // For screens less than 600px wide
          settings: {
            slidesToShow: 1, // Show 1 slide at a time
            slidesToScroll: 1,
            arrows: false,
            infinite: true,
            dots: false
          }
        }
      ]
      
    };

    const slides = [
      {
        delay: 100,
        title: " Repair Services",
        description: "Minor repairs like addressing electrical issues, ensuring fluid levels, servicing wheels and tires, adjusting brakes and suspension...",
        link: "repair-services",
        alt: "repair-services",
        image: essential
      },
      {
        delay: 200,
        title: "Master Dent & Paint",
        description: "Full body repaints, spot painting, paint blending, clear coat application, paint correction, cosmetic enhancements, dent repair, and more!",
        link: "master-dent-and-paint-solutions",
        alt: "master-dent-and-paint-solutions",
        image:master
      },
      {
        delay: 300,
        title: "Glamourous Detailing",
        description: "Comprehensive cleaning and restoration techniques tailored to luxury vehicles. It focuses on enhancing every detail...",
        link: "glamourous-details",
        alt : "glamourous-details",
        image:glamour
      },
      {
        delay: 400,
        title: "Ceramic Coating",
        description: "Wrenchit offers top-of-the-line Ceramic Coating services to provide your vehicle with long-lasting protection...",
        link: "ceramic-coating",
        alt : "ceramic-coating",
        image:creaming
      },
      {
        delay: 500,
        title: "PPF Services",
        description: "At Wrenchit, we offer superior Paint Protection Film (PPF) services designed to preserve and enhance your...",
        link: "ppf-services",
        alt : "ppf-services",
        image:PPF

      },
      {
        delay: 600,
        title: "Periodic Maintenance",
        description: "A well-serviced car operates more efficiently, reducing emissions and contributing to a cleaner environment....",
        link: "periodic-maintenance",
        alt : "periodic-maintenance",
        image:periodic
      }
      
      
    ];
  
  return (
    <div>
     <div className='service_slider'>
     <Slider {...settings}>
        {slides.map((slide, index) => (
          <ServicesCard 
            key={index} 
            delay={slide.delay}
            title={slide.title}
            description={slide.description}
            link={slide.link} 
            image={slide.image}
            alt={slide.alt} 
          />
        ))}
      </Slider>
     </div>
     
    </div>
  )
}

export default ServicesSlider