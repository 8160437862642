// src/components/about/Details.jsx

import React from "react";
import CarLogo from "../CarLogo";
import { Link } from "@mui/material";

const Details = () => {
  return (
    <section
      id="service-details"
      className="service-details section home-content  pb-0"
    >
      <div className="container about-sus">
        <div className="row gy-5">
          {/* <div className="col-lg-5" data-aos="fade-up" data-aos-delay="100">
            <h4>WHO WE ARE</h4>
            <h3>Car Detailing, Maintenance, & Repair Made Easier!</h3>
            <br />
            <a className="btn-getstarted f-right" href="contact.html">
              MORE ABOUT US
            </a>
            <br />
          </div> */}
          <div
            className="col-lg-12 video-play-sec"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h2 className="font-weight-bold" style={{fontSize : '1.25rem'}}>About Wrenchit</h2>
            <p >
            Welcome to <a href="/"> Wrenchit</a> , where luxury meets excellence in automotive services. Founded with a passion for precision and a commitment to innovation, Wrenchit aims to transform the automotive service industry by providing unparalleled care for luxury vehicles. 
            </p>
            <p className="pb-4">
            Our dedication to blending state-of-the-art technology with customer-centric solutions sets us apart as a leader in the field. At Wrenchit, we believe in delivering more than just service; we deliver an experience that combines the highest standards of automotive services with a deep commitment to sustainability and transparency.
            </p>
           {/* <a class="btn-getstarted">Chekcout Our Services</a></div> */}
          <div>
            
          <p>
                      Call Us Anytime!
                      <br />
                      <span>+91 9096410050</span>
                    </p>
          </div>
            <hr />
           
          </div>
        </div>
      </div>
      <div className="container aboutimg mb-5 ourvalues">
      <h3 className="text-center pb-5">BRANDS WE SERVE</h3>

<CarLogo/>
      </div>
      
      <div className="container ourvalues">
        <div className="row">
          <div className="col-12">
            <h4>Our Values</h4>
            <h3>Commitment to Excellence, Sustainability & Transparency</h3>
            <p>
            We uphold the highest standards of quality and integrity in every aspect of our work, ensuring exceptional service and satisfaction for our clients.
            </p>
          </div>
          <div className="row gy-4 icon-boxes">
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
              <div className="icon-box">
                <i className="bi bi-buildings"></i>
                <h3> Mission</h3>
                <p>
                At Wrenchit, our mission is to redefine luxury automotive service with excellence, eco-conscious practices, and personalized experiences. We prioritize sustainability, innovation, and cutting-edge technology to ensure seamless, transparent service. Our goal is to set new industry standards and be the top choice for discerning car owners seeking the best in automotive care.                </p>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
              <div className="icon-box">
                <i className="bi bi-clipboard-pulse"></i>
                <h3> Vision</h3>
                <p>
                Our vision is to lead the luxury automotive services landscape by offering luxury standards at exceptional value, a customer-centric approach, and unwavering commitment to business excellence. We aim to expand nationwide, becoming the trusted choice for luxury car owners. Through continuous innovation and dedication, we aspire to set benchmarks in the industry.                </p>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
              <div className="icon-box">
                <i className="bi bi-clipboard-pulse"></i>
                <h3> Motto</h3>
                <p>
                "Excellence in Every Aspect" is our promise. At Wrenchit, we believe true luxury lies in the details. We strive for perfection in every service, ensuring your vehicle looks, feels, and performs at its best. Entrust us with your vehicle, and expect meticulous attention to every detail.                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
