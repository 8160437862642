import React from "react";
import key from "../../assets/images/Key-up.png";
import ListItem from "../../Components/ListItem/ListItem";
import serviceFirstImg from "../../assets/images/services/periodic/Periodic maintenance 600x380.webp";
import image2 from "../../assets/images/services/periodic/Periodic maintenance why wrenchit 300x300.webp";
import Lights from "../../assets/images/services/periodic/LIGHTS AND ELECTRICAL COMPONENTS.png";
import oilFilter from "../../assets/images/services/periodic/OIL AND FILTER CHANGE.png";
import FluidChecks from "../../assets/images/services/periodic/FLUID CHECKS AND REPLACEMENTS.png";
import BrakeSystem from "../../assets/images/services/periodic/BRAKE SYSTEM INSPECTION.png";
import TireMaintenance from "../../assets/images/services/periodic/TIRE MAINTENANCE.png";
import Battery  from "../../assets/images/services/periodic/BATTERY CHECK.png";
import FilterReplacements from "../../assets/images/services/periodic/FILTER REPLACEMENTS.png";
import Inspection from "../../assets/images/services/periodic/INSPECTION OF BELTS AND HOSES.png";
import Spark from "../../assets/images/services/periodic/SPARK PLUG REPLACEMENT.png";
import  Suspension from "../../assets/images/services/periodic/SUSPENSION AND STEERING SYSTEM CHECK.png";
import Exhaust from "../../assets/images/services/periodic/EXHAUST SYSTEM INSPECTION.png";
import DriveAxles  from "../../assets/images/services/periodic/INSPECTION OF DRIVE AXLES AND CV JOINTS.png";
import AdditionalChecks from "../../assets/images/services/periodic/ADDITIONAL CHECKS BASED ON MANUFACTURER RECOMMENDATIONS.png";
import BodyandChassis from "../../assets/images/services/periodic/BODY AND CHASSIS INSPECTION.png";
import InteriorChecks from "../../assets/images/services/periodic/INTERIOR CHECKS.png";
import ServicesImages from "../../Components/ServicesImages";
import ServicesSidebar from "../../Components/sidebar/ServicesSidebar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
function PeriodicMaintenance() {
  const listItems = [
    "Certified and experienced technicians",
    "Comprehensive services offered",
    "Convenient scheduling options",
    "Customer satisfaction guaranteed",
    "Exceptional pricing",
  ];
  const generateAltText = (title) => title.toLowerCase().replace(/\s+/g, '-');

  return (
    <main className="main">
      <Helmet>
        <title>Periodic Car Maintenance Services in Pune | Wrenchit Experts</title>
        <meta
          name="description"
          content="Keep your car running smoothly with Wrenchit’s periodic car maintenance services in Pune. Our certified technicians ensure optimal performance, safety, and longevity."
        />
        <link rel="canonical" href="https://wrenchit.co.in/periodic-maintenance" />

        "<meta property="og:title" content="Periodic Car Maintenance Services in Pune | Wrenchit Experts" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/periodic-maintenance" />
        <meta property="og:description" content="Keep your car running smoothly with Wrenchit’s periodic car maintenance services in Pune. Our certified technicians ensure optimal performance, safety, and longevity." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/static/media/Periodic%20maintenance%20600x380.997e76b0f8ec5258d423.jpg" />
      </Helmet>
      {/* Page Title */}
      <div className="page-title services-banner" data-aos="fade">
        <div className="heading">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-8 main-banner">
                <h1 className="">Periodic Maintenance</h1>
                <p className="mb-0">Services &gt; Periodic Maintenance</p>
              </div>
            </div>
          </div>
        </div>
        <nav className="secound-banner-sec">
          <div className="container">
            <div className="left-div">
            <p>
                  {" "}
                  Get expert care for your luxury vehicle right at<br/> your doorstep.
                </p>
            </div>
            <div className="Right-div">
            <Link className="btn-getstarted f-right" to="/contact">
                  BOOK NOW
                </Link>
              <img src={key} alt="Key" />
            </div>
          </div>
        </nav>
      </div>
      {/* End Page Title */}

      {/* Service Details Section */}
      <section id="service-details" className="service-details section">
        <div className="container">
          <div className="row gy-5 ">
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
             <ServicesSidebar/>
            </div>
            <div
              className="col-lg-8 ps-lg-5 services-des"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h3>Periodic Maintenance</h3>
              <p className="h5">
                A well-serviced car operates more efficiently, reducing
                emissions and contributing to a cleaner environment.
              </p>
              <p>
                At Wrenchit, we understand that routine car maintenance is
                crucial for optimal vehicle performance and safety. Our
                comprehensive maintenance services are designed to keep your car
                running efficiently. Our certified technicians specialize in all
                aspects of car care, from periodic maintenance and preventive
                services to expert repairs and engine diagnostics.
              </p>
              <p>
                Regular maintenance helps prevent automotive breakdowns, extends
                your car's lifespan, and ensures a smoother, safer driving
                experience. You can trust Wrenchit for proven technical
                expertise and meticulous care your car deserves, so you can
                enjoy peace of mind and confidence on the road.
              </p>
              <img
                src={serviceFirstImg}
                alt={generateAltText("Periodic Maintenance services")}
                className="img-fluid services-img"
              />
               <h3 className="mt-4" style={{ lineHeight: 1.3 }}>Why Wrenchit?</h3>
                  <p className="mb-2">
                    Discover the Wrenchit difference with unmatched expertise,
                    convenience, and reliability for all your car maintenance
                    needs.
                  </p>
              <div className="row mt-3">
                <div className="col-md-6">
                 
                  <ul className="comman-is  mb-5">
                    {listItems.map((item, index) => (
                      <ListItem key={index} text={item} />
                    ))}
                  </ul>
                </div>
                <div className="col-md-6">
                  <div className="comman-issue">
                    <img src={image2} alt={generateAltText("Periodic Maintenance Why wrenchit")} className="img-fluid pt-0" />
                  </div>
                </div>
              </div>
          
            </div>
          </div>
              {/* Services Section */}
              <main id="services" className="services section1   pb-0">
                <div className="Benefits BenefitsSection ">
                  <div className="container p-0 mb-4" data-aos="fade-up">
                    <h2>What We Do For You?</h2>
                    <p>
                      At Wrenchit, we go beyond basic maintenance, offering
                      comprehensive check-up to ensure your car performs at its
                      best.
                    </p>
                    <p className="pb-4">
                      Our periodic services include a range of tasks and
                      check-ups essential for maintaining your vehicle's health
                      and performance. Here’s what we can do for you:
                    </p>
                  </div>
                  <div className="container p-0">
                    <div className="row  g-4 essential_car_services">
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                            <img src={oilFilter} 
                            alt={generateAltText("Oil and Filter Change")}
                            ></img>
                          </div>
                          <div>
                            <h4 className="title">Oil and Filter Change</h4>
                            <p className="description">
                              We replace engine oil and oil filter to ensure
                              proper lubrication of engine components and
                              maintain engine health.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={FluidChecks} alt={generateAltText("Fluid Checks and Replacements")}></img>
                          </div>
                          <div>
                            <h4 className="title">
                              Fluid Checks and Replacements
                            </h4>
                            <p className="description">
                              Coolant check and top up or replacement, checking
                              and replacing brake fluid, inspect and replace
                              transmission fluid, check and top up or replace
                              power steering fluid for safe and effortless
                              driving. Also, we ensure the washer fluid is
                              topped up for clear visibility.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={BrakeSystem} alt={generateAltText("Brake System Inspectio")}></img>
                          </div>
                          <div>
                            <h4 className="title">Brake System Inspection</h4>
                            <p className="description">
                              We check brake pads, discs, and calipers for wear
                              and tear, inspect brake lines and hoses for leaks
                              or damage, and ensure proper operation of the
                              brake pedal.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={TireMaintenance} alt={generateAltText("Tire Maintenance")}></img>
                          </div>
                          <div>
                            <h4 className="title">Tire Maintenance</h4>
                            <p className="description">
                              We check tire pressure and adjust to recommended
                              levels, inspect tires for tread wear and damage,
                              rotate tires to ensure even wear and extend tire
                              life, and check wheel alignment and balance.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={Battery} alt={generateAltText("Battery Check")}></img>

                          </div>
                          <div>
                            <h4 className="title">Battery Check</h4>
                            <p className="description">
                              We inspect battery terminals and cables for
                              corrosion and secure connections, and check
                              battery charge and replace if necessary.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={FilterReplacements} alt={generateAltText("Filter Replacements")}></img>

                          </div>
                          <div>
                            <h4 className="title">Filter Replacements</h4>
                            <p className="description">
                              We replace the air filter to ensure clean airflow
                              to the engine, and replace the cabin air filter to
                              maintain air quality inside the vehicle.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={Inspection} alt={generateAltText("Inspection of Belts and Hoses")}></img>

                          </div>
                          <div>
                            <h4 className="title">
                              Inspection of Belts and Hoses
                            </h4>
                            <p className="description">
                              We check the condition of drive belts such as the
                              serpentine belt and replace if cracked or worn,
                              and inspect hoses for leaks, cracks, or bulges and
                              replace as needed.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={Spark} alt={generateAltText("Spark Plug Replacement")}></img>
                          </div>
                          <div>
                            <h4 className="title">Spark Plug Replacement</h4>
                            <p className="description">
                              We inspect and replace spark plugs to ensure
                              efficient combustion and engine performance.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={Suspension} alt={generateAltText(" Suspension and Steering System Check")}></img>
                          </div>
                          <div>
                            <h4 className="title">
                              Suspension and Steering System Check{" "}
                            </h4>
                            <p className="description">
                              We inspect shock absorbers, struts, and other
                              suspension components for wear and leaks, and
                              check the steering system for play and proper
                              operation.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={Lights} alt={generateAltText(" Lights and Electrical Components")}></img>

                          </div>
                          <div>
                            <h4 className="title">
                              Lights and Electrical Components
                            </h4>
                            <p className="description">
                              We test all exterior and interior lights,
                              including headlights, taillights, turn signals,
                              and brake lights, and check the operation of
                              electrical components like the horn, wipers, and
                              power windows.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={Exhaust} alt={generateAltText("Exhaust System Inspection")}></img>

                          </div>
                          <div>
                            <h4 className="title">
                              Exhaust System Inspection{" "}
                            </h4>
                            <p className="description">
                              We inspect the exhaust system for leaks, damage,
                              or unusual noises.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={DriveAxles} alt={generateAltText("Inspection of Drive Axles and CV Joints")}></img>

                          </div>
                          <div>
                            <h4 className="title">
                              Inspection of Drive Axles and CV Joints{" "}
                            </h4>
                            <p className="description">
                              We check for any signs of wear or damage in drive
                              axles and CV joints, which are crucial for vehicle
                              movement.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={AdditionalChecks} alt={generateAltText("Additional Checks Based on Manufacturer Recommendations")}></img>

                          </div>
                          <div>
                            <h4 className="title">
                              Additional Checks Based on Manufacturer
                              Recommendations
                            </h4>
                            <p className="description">
                              We check and replace specific components according
                              to the vehicle's maintenance schedule, such as the
                              timing belt and fuel filter.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={BodyandChassis} alt={generateAltText("Body and Chassis Inspection")}></img>

                          </div>
                          <div>
                            <h4 className="title">
                              Body and Chassis Inspection
                            </h4>
                            <p className="description">
                              We inspect the body and chassis for any signs of
                              rust, corrosion, or damage.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={InteriorChecks } alt={generateAltText("Interior Checks")}></img>

                          </div>
                          <div>
                            <h4 className="title">Interior Checks </h4>
                            <p className="description">
                              We check and maintain interior components,
                              including the condition of the seats, dashboard,
                              and any electronic systems.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-5 mb-0">
                  At Wrenchit, we offer a comprehensive range of periodic car
                  maintenance services. We offer basic checks and oil changes
                  for every 3,000 to 5,000 miles, more comprehensive checks and
                  replacements, including filters and fluids for every 15,000 to
                  30,000 miles, and major maintenance, including timing belt
                  replacement, and in-depth inspections for further distances
                  traveled. With convenient scheduling and exceptional pricing,
                  we guarantee customer satisfaction, every time!
                </p>
                {/* <ServicesImages/> */}
              </main>
              {/* End Services Section */}
        </div>
      </section>

      {/* End Service Details Section */}
    </main>
  );
}

export default PeriodicMaintenance;
