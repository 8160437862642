import React from "react";
import key from "../assets/images/Key-up.png";
import ListItem from "../Components/ListItem/ListItem";
import serviceFirstImg from "../assets/images/services/PPF/ppf 600x380.webp";
import image2 from "../assets/images/services/PPF/ppf why wrenchit 300x300.webp";
import GLOSS from "../assets/images/services/PPF/CLARITY AND GLOSS.png";
import CUSTOM from "../assets/images/services/PPF/CUSTOM FIT.png";
import DURABILITY from "../assets/images/services/PPF/DURABILITY.png";
import INSTALLATION from "../assets/images/services/PPF/EXPERT INSTALLATION.png";
import TECHNOLOGY from "../assets/images/services/PPF/SELF-HEALING TECHNOLOGY.png";
import ULTIMATE from "../assets/images/services/PPF/ULTIMATE PROTECTION.png";
import ServiceCardItem from "../Components/ServiceCardItem/ServiceCardItem";
import PricingServices from "../Components/PricingServices";
import ServicesSidebar from "../Components/sidebar/ServicesSidebar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
const PPFServices = () => {
  const listItems = [
    "Thorough Cleaning: We begin with a meticulous wash to remove dirt and contaminants.",
    "Surface Decontamination: Clay bar treatment to eliminate embedded particles, ensuring a smooth surface.",
    "Polishing: Minor paint corrections to remove swirl marks and enhance gloss before PPF application.",
  ];
  const listItems2 = [
    "Custom-Cut Films: Using advanced techniques, we cut PPF to match your vehicle’s specifications perfectly.",
    "Seamless Installation: Our experts apply the film with precision, ensuring no air bubbles or imperfections.",
    "Edge Wrapping: Critical areas like edges and corners are wrapped for maximum protection and an invisible finish.",
  ];
  const listItems3 = [
    "Final Inspection: Comprehensive quality check to ensure flawless application.",
    "Initial Curing: Ensuring the film adheres properly and begins the curing process.",
    "Aftercare Guidance: Detailed instructions on how to maintain your vehicle post-PPF application to ensure longevity.",
  ];
  const listItems4 = [
    "Protection against minor scratches and chips.",
    "High gloss and clarity with self-healing properties.",
    "Suitable for those seeking high-quality protection for shorter duration.",
  ];
  const listItems5 = [
    "Enhanced thickness for better protection against environmental damage.",
    "Advanced self-healing technology.",
    "Ideal for vehicle owners who want extended protection and durability.",
  ];
  const listItems6 = [
    "Superior protection with increased thickness and durability.",
    "Perfect for luxury vehicle owners looking for top-tier protection and aesthetics.",
  ];
  const listItems7 = [
    "Maximum protection with the highest grade of PPF.",
    "Best-in-class self-healing properties and clarity.",
    "Designed for those who demand the ultimate in vehicle protection and appearance.",
  ];
  const generateAltText = (title) => title.toLowerCase().replace(/\s+/g, '-');

  const services = [
    {
      icon: "bi-briefcase",
      title: "Ultimate Protection",
      description:
        "Our PPF offers unmatched protection against scratches, stone chips, and other potential damage.",
      delay: 100,
      alt: generateAltText("Ultimate Protection"),
      image:ULTIMATE
    },
    {
      icon: "bi-card-checklist",
      title: "Durability",
      description:
        "Available in 3, 5, 8, and 10-year options to suit your specific needs.",
      delay: 200,
      alt: generateAltText("Durability"),
      image:DURABILITY
    },
    {
      icon: "bi-clock",
      title: "Clarity and Gloss",
      description:
        "Enhances the appearance of your vehicle with a high-gloss finish that maintains the original paint’s clarity.",
      delay: 300,
      image:GLOSS
    },
    {
      icon: "bi-clock",
      title: "Self-Healing Technology",
      description:
        "Minor scratches and swirl marks disappear with heat, ensuring a flawless look.",
      delay: 300,
      alt: generateAltText("Self-Healing Technology"),
      image:TECHNOLOGY
    },
    {
      icon: "bi-clock",
      title: "Expert Installation",
      description:
        "Our certified technicians ensure a seamless application with precision and care.",
      delay: 300,
      alt: generateAltText("Comprehensive Cleaning"),
      image:INSTALLATION
    },
    {
      icon: "bi-clock",
      title: "Custom Fit",
      description:
        "PPF is custom-cut to fit every contour of your vehicle for maximum coverage.",
      delay: 300,
      alt: generateAltText("Custom Fit"),
      image:CUSTOM
    },
  ];

  return (
    <>
  

      <main className="main">
      <Helmet>
        <title>Premium Car Paint Protection Film (PPF) Services in Pune | Wrenchit</title>
        <meta
          name="description"
          content="Protect your vehicle’s paint with Wrenchit’s Car Paint Protection Film (PPF) Services in Pune. Our durable, transparent film guards against damage, with 3 to 10 year options."
        />
        <link rel="canonical" href="https://wrenchit.co.in/ppf-services" />

        "<meta property="og:title" content="Premium Car Paint Protection Film (PPF) Services in Pune | Wrenchit" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/ppf-services" />
        <meta property="og:description" content="Protect your vehicle’s paint with Wrenchit’s Car Paint Protection Film (PPF) Services in Pune. Our durable, transparent film guards against damage, with 3 to 10 year options." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/static/media/ppf%20600x380.c175adc50911d6bd9312.jpg" />
      </Helmet>
        {/* Page Title */}
        <div className="page-title services-banner" data-aos="fade">
          <div className="heading">
            <div className="container">
              <div className="row d-flex justify-content-center text-center">
                <div className="col-lg-8 main-banner">
                  <h1 className="">Paint Protection Film (PPF) Services</h1>
                  <p className="mb-0">
                    Services &gt; Paint Protection Film (PPF) Services
                  </p>
                </div>
              </div>
            </div>
          </div>
          <nav className="secound-banner-sec">
            <div className="container">
              <div className="left-div">
                <p>
                  {" "}
                  Get expert care for your luxury vehicle right at<br/> your doorstep.
                </p>
               
              </div>
              <div className="Right-div">
              <Link className="btn-getstarted f-right" to="/contact">
                  BOOK NOW
                </Link>
                <img src={key} alt="Key" />
              </div>
            </div>
          </nav>
        </div>
        {/* End Page Title */}

        {/* Service Details Section */}
        <section id="service-details" className="service-details section">
          <div className="container">
            <div className="row gy-5">
              <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
               <ServicesSidebar/>
              </div>
              <div
                className="col-lg-8 ps-lg-5 services-des"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                 <h2 className="service_h2">Paint Protection Film (PPF) Services</h2>
                <p>
                  At Wrenchit, we offer superior Paint Protection Film (PPF)
                  services designed to preserve and enhance your vehicle's
                  aesthetic appeal and protect its beauty for years. We apply a
                  transparent, durable PPF layer that safeguards your car's
                  paint from environmental damage, road debris, and minor
                  abrasions.
                </p>
                <p>
                  With options for 3, 5, 8, and 10-year durability, our PPF
                  ensures your vehicle remains in pristine condition, providing
                  a robust shield against everything that can damage its body
                  paint.
                </p>
                <img
                  src={serviceFirstImg}
                  alt={generateAltText("Paint Protection Film Services")}
                  className="img-fluid services-img"
                />
             
                <div className="row mt-3">
                <h3>Why Wrenchit?</h3>
                  <p className="mb-4">
                  At Wrenchit, we bring decades of experience and technical expertise to every paint protection film we apply. Our certified technicians are trained to apply PPF with meticulous attention to detail, ensuring your vehicle’s paintwork remains pristine.

                  </p>
                  <div className="col-md-6">
                 <ul>
                  <li>
                 <b> Premium Quality Products:</b> We use only the highest quality paint protection films available in the market.

                  </li>
                  <li>
                 <b> Flexible Durability:</b> Our PPF options come with durability guarantees of 3, 5, 8, and 10 years, providing long-lasting protection against scratches, stone chips, and environmental contaminants.

                  </li>
                  <li>
                 <b>
                 Advanced Technology:
                  </b> Utilizing state-of-the-art technology and precision-cut patterns, we ensure a perfect fit for your vehicle.

                  </li>
                  <li>
                 <b> Comprehensive Coverage:</b> Whether you want to protect high-impact areas or your entire vehicle, Wrenchit has you covered.
                  </li>
                 </ul>
               
                  </div>
                  <div className="col-md-6">
                    <div className="comman-issue">
                      <img src={image2} 
                  alt={generateAltText("Paint Protection Film Why Wrenchit")}
                      
                      className="img-fluid pt-0" />
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
             {/* Services Section */}
             <main id="services" className="services section1 pb-0">
                  <div className="Benefits BenefitsSection  px-0">
                    <div className="container p-0 mb-4" data-aos="fade-up">
                      <h3>Major Benefits of Our PPF Services </h3>
                      <p>
                        Our expertise, state-of-the-art technology, use of
                        top-notch products, and commitment to customer
                        satisfaction ensure that your vehicle receives the best
                        possible PPF service ever! Here's why Wrenchit stands
                        out as the top choice for PPF services.
                      </p>
                    </div>
                    <div className="container pb-4 px-0">
                      <h4>Paintwork Enhancement</h4>
                      <p className="pb-3">
                        Under paintwork enhancement, we offer the following
                        services:
                      </p>
                      <div className="row g-4 essential_car_services">
                        {services.map((service, index , alt) => (
                          <ServiceCardItem
                            key={index}
                            alt={service.alt}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                            delay={service.delay}
                            image={service.image}
                          />
                        ))}
                      </div>
                    </div>
                    <hr></hr>
                    <div className="container p-0 pt-5 mb-4" data-aos="fade-up">
                      <h3>Our PPF Workflow</h3>
                      <p>
                        Our PPF experts use cutting-edge technologies for every
                        single step, from surface preparation, PPF application,
                        to post-application care.
                      </p>
                    </div>
                    <div className="container p-0 pt-4">
                      <h4 className="mb-2">
                        Comprehensive Surface Preparation
                      </h4>
                      <div className="row g-2">
                        {listItems.map((item, index) => (
                          <ListItem key={index} text={item} noIcon={true} />
                        ))}
                      </div>
                    </div>
                    <div className="container p-0 pt-4">
                      <h4 className="mb-2">Precision PPF Application</h4>
                      <div className="row g-2">
                        {listItems2.map((item, index) => (
                          <ListItem key={index} text={item} noIcon={true} />
                        ))}
                      </div>
                    </div>
                    <div className="container p-0 pt-4">
                      <h4 className="mb-2">Post-Application Care </h4>
                      <div className="row g-2">
                        {listItems3.map((item, index) => (
                          <ListItem key={index} text={item} noIcon={true} />
                        ))}
                      </div>
                    </div>
                    <hr className="mt-4"></hr>
                    {/* <div className="container p-0 pt-4 mb-4" data-aos="fade-up">
                      <h3>Wrenchit's PPF Packages</h3>
                      <p>
                        We offer four different PPF service packages, so that
                        you can choose one as per your convenience.
                      </p>
                    </div> */}
                   <PricingServices formId="form_one"/>
                  </div>
                  <p className="mt-4 mb-0">
                    Experience unparalleled protection and aesthetic enhancement
                    with Wrenchit’s PPF services. Contact us today to schedule
                    your PPF installation and keep your vehicle looking brand
                    new for years to come.
                  </p>
                </main>
                {/* End Services Section */}
          </div>
        </section>

        {/* End Service Details Section */}
      </main>
    </>
  );
};

export default PPFServices;
